<template lang="pug">
  .page.options-page

    el-form.page__container.options-page__container(ref="postForm")
      .page__body(v-show="!dataLoading")
        h2.leasing-calculator-requests__title(v-html="data.title")

        tab-fields(:fields="fields" v-model="postForm")

        a(:href="link" target="_blank" download)
          el-button(type="primary" v-html="data.button_text")

    back-to-top
</template>

<script>
import ActionsLine from '@/components/PagePartials/ActionsLine'
import PreviewDialog from '@/components/PagePartials/PreviewDialog'
import TabFields from '@/components/fields/TabFields'
import { notifyRequest, notifyErrorRequest } from '@/utils/api'
import SaveRoutes from '@/views/Templates/SaveRoutes'
import BackToTop from '@/components/BackToTop'
import Locks from '@/components/Locks'

export default {
  name: 'LeasingCalculatorPage',
  extends: [SaveRoutes],
  components: {
    TabFields,
    BackToTop,
  },
  data() {
    return {
      data: {},
      postForm: {},
      fields: null,
      loading: true,
      dataLoading: true,
    }
  },
  computed: {
    request() {
      return this.$route.meta.request
    },
    link() {
      if (!this.data) {
        return ''
      }
      return this.data.button_link + '?' + Object.keys(this.postForm).map(key => `${key}=${this.postForm[key]}`).join('&')
    }
  },
  async created() {
    const fetchedData = await this.fetchData()
    this.data = fetchedData
    this.postForm.token = fetchedData.token
    this.fields = fetchedData.fields
    this.loading = false
    this.dataLoading = false
  },
  mounted() {
  },
  methods: {
    async fetchData() {
      const response = await notifyErrorRequest({
        url: `${this.request}`,
        method: 'get',
        params: {},
      })
      return response
    },
  },
}
</script>
<style rel="stylesheet/scss" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .leasing-calculator-requests__title
    font-size: 24px
    font-weight: 700
    color: #303133
</style>
